import React from 'react'
import { NavLink } from 'react-router-dom'
import './Home.css'
import About from '../../images/bg.jpg'
import Line from '../../images/linewhite.png'
// import Line2 from '../../images/linewhite2.png'
import Line2 from '../../images/line.png'
import chai from '../../images/chai.jpg'
import coffee from '../../images/coffe2.jpg'
import addons from '../../images/bunmaska.jpg'




function Home() {
    return (
        <>
            <main>
                <div className="home-container">
                    <h2>Welcome To</h2>
                    <img className="line" src={Line} alt="" />
                    <h1>Bakery Fortune Cafe</h1>
                    <p>At Fortune Bakers Cafe, we believe in the magic of freshly baked goods and the comfort of a cozy cafe environment. Nestled in the heart of Kharghar, our cafe is a haven for food lovers, offering a delightful range of baked treats, beverages, and savory dishes.
                    </p>
                    <button><NavLink to="/menu" className={() => `navlink`}>View Menu</NavLink></button>
                </div>
            </main>
            <div className="about-container">
                <div className="about-image">
                    <img src={About} alt="" />
                </div>
                <div className="about-text">
                    <h2>Taste the Fortune, Feel the Love</h2>
                    <img className="line" src={Line2} alt="" />
                    <p>At Fortune Bakers Cafe, we believe in the magic of freshly baked goods and the comfort of a cozy cafe environment. Nestled in the heart of Kharghar, our cafe is a haven for food lovers, offering a delightful range of baked treats, beverages, and savory dishes.
                    </p>

                    <div className="points">
                        <div className="point">
                            <i class="fa-brands fa-sketch"></i>
                            <h3>Quality Ingredients</h3>
                            <p>We use only the finest ingredients to ensure that every bite is delicious and satisfying.</p>
                        </div>
                        <div className="point">
                            <i class="fa-brands fa-phoenix-framework"></i>
                            <h3>Cozy Ambiance</h3>
                            <p>Our cafe offers a warm and inviting atmosphere, perfect for relaxing or catching up with friends.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="heading-of-menu">
                <h2>Our Menu</h2>
                <img src={Line2} alt="" />
            </div>
            <div className="home-menu-container">

                <div className="home-menu-box">
                    <img src={chai} alt="" className='home-menu-box-img' />
                    <div className="home-menu-box-content">
                        <h3>Chai Ki Chuski</h3>
                        <img src={Line2} alt="" />
                        <p>Indulge in the soothing warmth of our spiced chai, brewed to perfection, offering a refreshing sip of tradition..</p>
                    </div>
                </div>
                <div className="home-menu-box">
                    <img src={coffee} alt="" className='home-menu-box-img' />
                    <div className="home-menu-box-content">
                        <h3>Coffee Punch</h3>
                        <img src={Line2} alt="" />
                        <p>Enjoy a bold and invigorating coffee blend, crafted to energize your senses with every rich and flavorful sip.</p>
                    </div>
                </div>
                <div className="home-menu-box">
                    <img src={addons} alt="" className='home-menu-box-img' />
                    <div className="home-menu-box-content">
                        <h3>Add Ons</h3>
                        <img src={Line2} alt="" />
                        <p>Savor crispy butter toast, flaky khari, and fresh biscuits, offering the perfect accompaniment to your favorite beverages.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
