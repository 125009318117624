import React from 'react'
import PageBanner from '../../components/PageBanner/PageBanner'
import cafe from '../../images/bg2.jpg'
import Line2 from '../../images/line.png'
import LineWhite from '../../images/linewhite.png'
import './About.css'

function About() {
  return (
    <>
      <PageBanner pagename={"About Us"} info={"At Fortune Bakers Cafe, we believe in the magic of freshly baked goods and the comfort of a cozy cafe environment. "} />
      <div className="about-us">
        <img src={cafe} alt="" className='aboutImg' />
        <div className="about-us-info">
          <h1>Bakery Fortune Cafe</h1>
          <img className="line" src={Line2} alt="" />
          <h2>Best Cafe In Kharghar...! </h2>
          <p>At Fortune Bakers Cafe, we believe in the magic of freshly baked goods and the comfort of a cozy cafe environment. Nestled in the heart of Kharghar, our cafe is a haven for food lovers, offering a delightful range of baked treats, beverages, and savory dishes.
          </p>
        </div>
      </div>
      <div className="about-points">
        <div className="about-points-1">
          <h2>Unmatched Quality</h2>
          <img src={LineWhite} alt="" />
          <p>At Fortune Bakers Cafe, we are committed to using only the highest quality ingredients in everything we serve. From our freshly baked goods to our gourmet beverages, each item is crafted with care and precision to ensure a delightful experience in every bite.</p>
        </div>
        <div className="about-points-1">
          <h2>Soothing Atmosphere</h2>
          <img src={LineWhite} alt="" />
          <p>Our cafe is designed to be your cozy retreat in Kharghar. With a comfortable ambiance and inviting decor, Fortune Bakers Cafe is the perfect spot to relax, catch up with friends, or enjoy a quiet moment with a book and your favorite treat.</p>
        </div>
        <div className="about-points-1">
          <h2>Exceptional Service</h2>
          <img src={LineWhite} alt="" />
          <p>We believe that great food deserves great service. Our friendly and attentive staff are dedicated to making sure your visit to Fortune Bakers Cafe is nothing short of excellent. We go the extra mile to ensure that you feel welcome and cared for from the moment you walk in.</p>
        </div>
      </div>
    </>
  )
}

export default About
