import React from 'react'
import PageBanner from '../../components/PageBanner/PageBanner'
import Swiggy from '../../images/swiggy.jpg'
import Zomato from '../../images/zomato.png'
import Magicpin from '../../images/magicpin.png'
import './OrderNow.css'
import Line from '../../images/line.png'

function OrderNow() {
  return (
    <>
    <PageBanner pagename={"Order Now"} info={"At Fortune Bakers Cafe, we believe in the magic of freshly baked goods and the comfort of a cozy cafe environment. "}/>
    <div className="order-now">
        <h2>Call Now To Order</h2>
        <img className="line" src={Line} alt="" />
        <h1>+91 74047 94047</h1>
        <h3>-------------</h3>
        <h2>Order Online On</h2>
        <img className="line" src={Line} alt="" />
        <div className="online">
          <img src={Swiggy} alt="" />
          <img src={Zomato} alt="" />
          <img src={Magicpin} alt="" />
        </div>
    </div>
    </>
  )
}

export default OrderNow
