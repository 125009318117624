import React from 'react'
import PageBanner from '../../components/PageBanner/PageBanner'
import './Menu.css'
import Line2 from '../../images/line.png'

function Menu() {
  return (
    <>
      <PageBanner pagename={"Menu"} info={"At Fortune Bakers Cafe, we believe in the magic of freshly baked goods and the comfort of a cozy cafe environment. "} />
      <div className="menu-container">
        <div className="main-course curry">
          <div className="rowHeading">
            <h2>Curry Corner</h2>
            <img className="line" src={Line2} alt="" />
          </div>
          <div className="row">
            <h3>Diwani Handi</h3>
            <h3>Rs.249</h3>
          </div>
          <div className="row">
            <h3>Paneer Makhni</h3>
            <h3>Rs.279</h3>
          </div>
          <div className="row">
            <h3>Paneer Tikka Masala</h3>
            <h3>Rs.289</h3>
          </div>
          <div className="row">
            <h3>Butter Chicken</h3>
            <h3>Rs.319</h3>
          </div>
          <div className="row">
            <h3>Chicken Tikka Masala</h3>
            <h3>Rs.319</h3>
          </div>
          <div className="row">
            <h3>Chicken Tawa Masala</h3>
            <h3>Rs.319</h3>
          </div>
        </div>
        <div className="main-course roti">
          <div className="rowHeading">
            <h2>Roti Route</h2>
            <img className="line" src={Line2} alt="" />
          </div>
          <div className="row">
            <h3>Roti</h3>
            <h3>Rs.25</h3>
          </div>
          <div className="row">
            <h3>Butter Roti</h3>
            <h3>Rs.29</h3>
          </div>
          <div className="row">
            <h3>Laccha Paratha</h3>
            <h3>Rs.45</h3>
          </div>
          <div className="row">
            <h3>Naan</h3>
            <h3>Rs.39</h3>
          </div>
          <div className="row">
            <h3>Butter Naan</h3>
            <h3>Rs.45</h3>
          </div>
        
        </div>
        <div className="main-course chai">
          <div className="rowHeading">
            <h2>Chai Ki Chuski</h2>
            <img className="line" src={Line2} alt="" />
          </div>
          <div className="row">
            <h3>Tea</h3>
            <h3>Rs.49</h3>
          </div>
          <div className="row">
            <h3>Ginger Tea</h3>
            <h3>Rs.49</h3>
          </div>
          <div className="row">
            <h3>Green Tea</h3>
            <h3>Rs.39</h3>
          </div>
          <div className="row">
            <h3>Black Tea</h3>
            <h3>Rs.39</h3>
          </div>
        
        </div>
        <div className="main-course coffee">
          <div className="rowHeading">
            <h2>Coffee Punch</h2>
            <img className="line" src={Line2} alt="" />
          </div>
          <div className="row">
            <h3>Espresso</h3>
            <h3>Rs.79</h3>
          </div>
          <div className="row">
            <h3>Americano - Hot Coffee</h3>
            <h3>Rs.99</h3>
          </div>
          <div className="row">
            <h3>Cappuccino</h3>
            <h3>Rs.119</h3>
          </div>
          <div className="row">
            <h3>Cafe Latte</h3>
            <h3>Rs.129</h3>
          </div>
          <div className="row">
            <h3>Ice Americano</h3>
            <h3>Rs.99</h3>
          </div>
          <div className="row">
            <h3>Hot Chocolate</h3>
            <h3>Rs.99</h3>
          </div>
        
        </div>
        <div className="main-course addons">
          <div className="rowHeading">
            <h2>Add Ons</h2>
            <img className="line" src={Line2} alt="" />
          </div>
          <div className="row">
            <h3>Tea With Bun Maska</h3>
            <h3>Rs.59</h3>
          </div>
          <div className="row">
            <h3>Bun Maska</h3>
            <h3>Rs.35</h3>
          </div>
          <div className="row">
            <h3>Bread Butter</h3>
            <h3>Rs.39</h3>
          </div>
          <div className="row">
            <h3>Butter - 5 piece</h3>
            <h3>Rs.25</h3>
          </div>
          <div className="row">
            <h3>Toast - 5 piece</h3>
            <h3>Rs.25</h3>
          </div>
          <div className="row">
            <h3>Khari - 5 piece</h3>
            <h3>Rs.25</h3>
          </div>
        
        </div>
        <div className="main-course kheema">
          <div className="rowHeading">
            <h2>Kheema Ka Khajana</h2>
            <img className="line" src={Line2} alt="" />
          </div>
          <div className="row">
            <h3>Chicken Kheema</h3>
            <h3>Rs.249</h3>
          </div>
          <div className="row">
            <h3>Chicken Kheema Gotala</h3>
            <h3>Rs.289</h3>
          </div>
          <div className="row">
            <h3>Mutton Kheema</h3>
            <h3>Rs.349</h3>
          </div>
          <div className="row">
            <h3>Mutton Kheema Gotala</h3>
            <h3>Rs.389</h3>
          </div>
          <div className="row">
            <h3>Veg Kheema</h3>
            <h3>Rs.199</h3>
          </div>
          <div className="row">
            <h3>Paneer Bhurji</h3>
            <h3>Rs.289</h3>
          </div>
          <div className="row">
            <h3>Egg Bhurji</h3>
            <h3>Rs.155</h3>
          </div>
          <div className="row">
            <h3>Cheese Egg Bhurji</h3>
            <h3>Rs.169</h3>
          </div>
          <div className="row">
            <h3>Chicken Cheese Bhurji</h3>
            <h3>Rs.269</h3>
          </div>
        
        </div>
        <div className="main-course egg">
          <div className="rowHeading">
            <h2>Ande Ka Funda</h2>
            <img className="line" src={Line2} alt="" />
          </div>
          <div className="row">
            <h3>Half Fry</h3>
            <h3>Rs.89</h3>
          </div>
          <div className="row">
            <h3>Full Fry</h3>
            <h3>Rs.89</h3>
          </div>
          <div className="row">
            <h3>Masala Omelette</h3>
            <h3>Rs.119</h3>
          </div>
          <div className="row">
            <h3>Masala Cheese Omelette</h3>
            <h3>Rs.149</h3>
          </div>
          <div className="row">
            <h3>Spanish Omelette</h3>
            <h3>Rs.109</h3>
          </div>
          <div className="row">
            <h3>Scrambeled Egg</h3>
            <h3>Rs.129</h3>
          </div>
          <div className="row">
            <h3>Boiled Egg - 2 piece</h3>
            <h3>Rs.69</h3>
          </div>
        
        </div>
      </div>
    </>
  )
}

export default Menu
