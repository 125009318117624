import React from 'react'
import PageBanner from '../../components/PageBanner/PageBanner'
import LineWhite from '../../images/linewhite.png'
import './ClientReviews.css'

function ClientReviews() {
  return (
    <>
      <PageBanner pagename={"Client Reviews"} info={"At Fortune Bakers Cafe, we believe in the magic of freshly baked goods and the comfort of a cozy cafe environment. "} />
      <div className="review-points">
        <div className="review-points-1">
          <h2>Aisha Patel</h2>
          <h3>Mumbai</h3>
          <img src={LineWhite} alt="" />
          <p>"Fortune Bakers Cafe is my go-to spot for a quick coffee and pastry. The ambiance is perfect for unwinding, and their cakes are absolutely delicious. Highly recommend it!"</p>
        </div>
        <div className="review-points-1">
          <h2>Rajesh Nair</h2>
          <h3>Navi Mumbai</h3>
          <img src={LineWhite} alt="" />
          <p>"I love the variety they offer, especially the fresh bread. The staff is always welcoming, and the cafe has a cozy vibe. It's a hidden gem in Kharghar!"</p>
        </div>
        <div className="review-points-1">
          <h2>Sneha Patil</h2>
          <h3>Thane</h3>
          <img src={LineWhite} alt="" />
          <p>"Visited Fortune Bakers Cafe with friends last weekend. The sandwiches were fantastic, and the coffee was spot on. It's a great place to catch up and enjoy good food."</p>
        </div>
        <div className="review-points-1">
          <h2>Arjun Shinde</h2>
          <h3>Pune</h3>
          <img src={LineWhite} alt="" />
          <p>"I was passing through Kharghar and stumbled upon this cafe. The pastries are to die for! The golden-black decor adds a classy touch, making it a perfect spot to relax."</p>
        </div>
        <div className="review-points-1">
          <h2>Priya Deshmukh</h2>
          <h3>Panvel</h3>
          <img src={LineWhite} alt="" />
          <p>"Fortune Bakers Cafe never disappoints. Their attention to detail in every dish is commendable. The environment is peaceful, and the service is top-notch. I keep coming back for more!"</p>
        </div>
        <div className="review-points-1">
          <h2>Vikram Sharma</h2>
          <h3>Belapur</h3>
          <img src={LineWhite} alt="" />
          <p>"The quality of food and beverages here is exceptional. I especially love their pizzas and smoothies. The staff is friendly, and the cafe has a warm, inviting atmosphere. A must-visit!"</p>
        </div>
        
      </div>
    </>
  )
}

export default ClientReviews
