import React from 'react'
import PageBanner from '../../components/PageBanner/PageBanner'
import './Gallery.css'


function Gallery() {
  return (
    <>
    <PageBanner pagename={"Gallery"} info={"At Fortune Bakers Cafe, we believe in the magic of freshly baked goods and the comfort of a cozy cafe environment. "}/>
    <div className="gallery">
        <div className="gbox galleryBox1 row2">
      
        </div>
        <div className="gbox galleryBox2 col2">

        </div>
        <div className="gbox galleryBox3">

        </div>
        <div className="gbox galleryBox4 row2">

        </div>
        <div className="gbox galleryBox5 col2">

        </div>
        <div className="gbox galleryBox6">

        </div>
        <div className="gbox galleryBox7 col2">

        </div>
    </div>
    </>
  )
}

export default Gallery
