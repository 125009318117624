import React from 'react'
import { NavLink } from 'react-router-dom'
import './Footer.css'

function Footer() {
  return (
    <footer className='footer'>
      <div className="main-footer">
        <div className="box1">
          <h2>Quick Links</h2>

          <li><NavLink to="/" className={() => `navlink`}>Home</NavLink></li>
          <li><NavLink to="/about" className={() => `navlink`}>About Us</NavLink></li>
          <li><NavLink to="/menu" className={() => `navlink`}>Menu</NavLink></li>
          <li><NavLink to="/gallery" className={() => `navlink`}>Gallery</NavLink></li>
          <li><NavLink to="/contact" className={() => `navlink`}>Contact Us</NavLink></li>
        </div>
        <div className="box1">
          <h2>Menu</h2>
          <li><NavLink to="/menu" className={() => `navlink`}>Breakfast</NavLink></li>
          <li><NavLink to="/menu" className={() => `navlink`}>Lunch</NavLink></li>
          <li><NavLink to="/menu" className={() => `navlink`}>Dinner</NavLink></li>
          <li><NavLink to="/client-reviews" className={() => `navlink`}>Client Reviews</NavLink></li>
          <li><NavLink to="/order-now" className={() => `navlink`}>Order Now</NavLink></li>
        </div>
        <div className="box3">
          <h2>Connect With Us</h2>
          <li>
            <div className="iconInfo">
              <i className="fa-solid fa-location-dot"></i>
              <p>Shop no 04- 05, Dream Paradise, plot no 6d, Sector 17, Kharghar, Navi Mumbai, Maharashtra 410210</p>
            </div>
          </li>
          <li>
            <div className="iconInfo">
              <i className="fa-solid fa-phone"></i>
              <p>+91 74047 94047</p>
            </div>
          </li>
          <li>
            <div className="iconInfo">
              <i className="fa-solid fa-phone"></i>
              <p>admin@fortunecafe.com</p>
            </div>
          </li>
        </div>
        <div className="box4">
          <h2>Reach Us</h2>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.3705124081944!2d73.0796378752047!3d19.04744058215123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3648ef72dbd%3A0xfec142b9e64c8a78!2sFortune%20Bakery%20Cafe!5e0!3m2!1sen!2sin!4v1723266463895!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='fortune'></iframe>
        </div>
      </div>
      <div className="bottom-footer">
        <h3>&copy; Fortune Bakery Cafe - All Rights Reserved</h3>
        <h3>Designed By Shekhar Pandge</h3>
      </div>
    </footer>
  )
}

export default Footer
