import React from 'react'
import PageBanner from '../../components/PageBanner/PageBanner'
import './Contact.css'
import Line2 from '../../images/line.png'

function Contact() {
  return (
    <>
      <PageBanner pagename={"Contact"} info={"At Fortune Bakers Cafe, we believe in the magic of freshly baked goods and the comfort of a cozy cafe environment. "} />
      <div className="contact-info">
        <div className="contact-info-box">
          <i className="fa-solid fa-phone"></i>
          <p>+91 74047 94047</p>
        </div>
        <div className="contact-info-box">
          <i className="fa-solid fa-location-dot"></i>
          <p>Shop no 04- 05, Dream Paradise, plot no 6d, Sector 17, Kharghar, Navi Mumbai, Maharashtra 410210</p>
        </div>
        <div className="contact-info-box">
          <i className="fa-solid fa-phone"></i>
          <p>admin@fortunebakery.com</p>
        </div>
      </div>
      <div className="contact-form">
        <form action="" className='form'>
          <h2>Please Share Your Feedback...!</h2>
          <img className="line" src={Line2} alt="" />
          <input type="text" placeholder='Enter Your Name' />
          <input type="email" placeholder='Enter Your Email' />
          <input type="Phone" placeholder='Enter Your Name' />
          <input type="Location" placeholder='Enter Your Location' />
          <textarea cols={5} placeholder='Enter Your Feedback'></textarea>
        </form>
      </div>
      <div className="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.3705124081944!2d73.0796378752047!3d19.04744058215123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3648ef72dbd%3A0xfec142b9e64c8a78!2sFortune%20Bakery%20Cafe!5e0!3m2!1sen!2sin!4v1723266463895!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </>
  )
}

export default Contact
