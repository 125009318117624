import './App.css';
import { Route, Routes } from 'react-router-dom'
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import About from './pages/About/About';
import Home from './pages/Home/Home';
import Menu from './pages/Menu/Menu';
import Gallery from './pages/Gallery/Gallery';
import Contact from './pages/Contact/Contact';
import ClientReviews from './pages/ClientReviews/ClientReviews';
import OrderNow from './pages/OrderNow/OrderNow';
import ScrollTop from './components/ScrollToTop/Scroll'

function App() {
  return (
    <>
    <ScrollTop />
    <Header />
    <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/about' element={<About />}/>
          <Route path='/menu' element={<Menu />}/>
          <Route path='/gallery' element={<Gallery />}/>
          <Route path='/contact' element={<Contact />}/>
          <Route path='/client-reviews' element={<ClientReviews />}/>
          <Route path='/order-now' element={<OrderNow />}/>
        </Routes>
    <Footer />
    </>
  );
}

export default App;
