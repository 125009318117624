import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../../images/logo.jpg'
import Line from '../../images/line.png'
import './Header.css'

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    return (
        <header className='header'>
            <div className={isMenuOpen ? "mobileMenu open" : "close"}>
                <li><NavLink to="/" className="navlink" onClick={toggleMenu}>Home</NavLink></li>
                <li><NavLink to="/about" className="navlink" onClick={toggleMenu}>About Us</NavLink></li>
                <li><NavLink to="/menu" className="navlink" onClick={toggleMenu}>Menu</NavLink></li>
                <li><NavLink to="/gallery" className="navlink" onClick={toggleMenu}>Gallery</NavLink></li>
                <li><NavLink to="/contact" className="navlink" onClick={toggleMenu}>Contact Us</NavLink></li>
                <li><NavLink to="/client-reviews" className="navlink" onClick={toggleMenu}>Client Reviews</NavLink></li>
                <li><NavLink to="/order-now" className="navlink" onClick={toggleMenu}>Order Now</NavLink></li>
            </div>
            {/* <div className="mobileBtns">
                <button><NavLink to="/client-reviews" className={() => `navlink`}>Client Reviews</NavLink></button>
                <button><NavLink to="/order-now" className={() => `navlink`}>Order Now</NavLink></button>
            </div> */}
            <div className="topNavbar">
                <div className="left-topNavbar">
                    <div className="iconInfo">
                        <i className="fa-solid fa-location-dot"></i>
                        <p>Shop no 04- 05, Dream Paradise, plot no 6d, Sector 17, Kharghar, Navi Mumbai, Maharashtra 410210</p>
                    </div>

                    <div className="iconInfo">
                        <i className="fa-solid fa-phone"></i>
                        <p>+91 74047 94047</p>
                    </div>
                </div>
                <div className="mobile-topNavbar">
                    <i className="fa-solid fa-phone"></i>
                    <p>+91 74047 94047</p>
                </div>
                <div className="right-topNavbar">
                    <i class="fa-brands fa-facebook"></i>
                    <i class="fa-brands fa-instagram"></i>
                    <i class="fa-brands fa-youtube"></i>

                </div>
            </div>
            <div className="navbar">
                <div className="left-navbar">
                    <ul >
                        <li><NavLink to="/" className={() => `navlink`}>Home</NavLink></li>
                        <li><NavLink to="/about" className={() => `navlink`}>About Us</NavLink></li>
                        <li><NavLink to="/menu" className={() => `navlink`}>Menu</NavLink></li>
                        <li><NavLink to="/gallery" className={() => `navlink`}>Gallery</NavLink></li>
                        <li><NavLink to="/contact" className={() => `navlink`}>Contact Us</NavLink></li>
                    </ul>
                </div>
                <div className="center-navbar">
                    <img className="headline" src={Line} alt="" />
                    <img className="logo" src={Logo} alt="" />
                    <img className="headline" src={Line} alt="" />
                </div>
                <div className="mobileBtns">
                    <button><NavLink to="/order-now" className={() => `navlink`}>Order Now</NavLink></button>
                </div>
                <div className="right-navbar">
                    <button><NavLink to="/client-reviews" className={() => `navlink`}>Client Reviews</NavLink></button>
                    <button><NavLink to="/order-now" className={() => `navlink`}>Order Now</NavLink></button>
                </div>
                <div className="hamburger" onClick={toggleMenu}>
                    <i className={isMenuOpen ? "fa-solid fa-times" : "fa-solid fa-bars"}></i>
                </div>
            </div>
        </header>
    )
}

export default Header
