import React from 'react'
import Line from '../../images/linewhite.png'
import './PageBanner.css'

function PageBanner(props) {
    return (
        <div className='page-banner'>
            <h2>{props.pagename}</h2>
            <img src={Line} alt="" />
            <p>{props.info}</p>
        </div>
    )
}

export default PageBanner
